// undefined and None are deprecated
const notApplicable = [
  {
    value: 'notApplicable',
    label: 'Not Applicable'
  },
  {
    value: undefined,
    label: 'Undefined'
  },
  {
    value: 'none',
    label: 'None'
  }
];

const residencyPrograms = [
  {
    value: '4200111007',
    label: 'University of Alabama Medical Center Program'
  },
  {
    value: '4200111008',
    label: 'University of South Alabama Program'
  },
  {
    value: '4200121006',
    label: 'Brookwood Baptist'
  },
  {
    value: '4200300935',
    label: 'Mayo Clinic College of Medicine and Science (Arizona) Program'
  },
  {
    value: '4200312010',
    label:
      "Creighton University School of Medicine/St Joseph's Medical Center (Phoenix) Program"
  },
  {
    value: '4200321011',
    label: 'University of Arizona College of Medicine-Tucson Program'
  },
  {
    value: '4200331255',
    label: 'Creighton University'
  },
  {
    value: '4200421012',
    label: 'University of Arkansas for Medical Sciences Program'
  },
  {
    value: '4200500915',
    label: 'OPTI West/Hemet Valley Medical Center Program'
  },
  {
    value: '4200511013',
    label: 'University of California Davis Health Program'
  },
  {
    value: '4200511021',
    label: 'University of Southern California/LAC+USC Medical Center Program'
  },
  {
    value: '4200511023',
    label: 'UCLA David Geffen School of Medicine/UCLA Medical Center Program'
  },
  {
    value: '4200511035',
    label: 'Los Angeles County- Harbor-UCLA Medical Center Program'
  },
  {
    value: '4200512020',
    label: 'Kaiser Permanente Southern California (Los Angeles) Program'
  },
  {
    value: '4200521001',
    label: 'David Grant Medical Center Program'
  },
  {
    value: '4200521014',
    label: 'University of California (Irvine) Program'
  },
  {
    value: '4200521015',
    label: 'Loma Linda University Health Education Consortium Program'
  },
  {
    value: '4200521018',
    label: 'Cedars-Sinai Medical Center Program'
  },
  {
    value: '4200521027',
    label: 'University of California (San Diego) Medical Center Program'
  },
  {
    value: '4200521031',
    label: 'University of California (San Francisco) Program'
  },
  {
    value: '4200521034',
    label: 'Stanford Health Care- Sponsored Stanford University Program'
  },
  {
    value: '4200521914',
    label: 'Naval Medical Center (San Diego) Program'
  },
  {
    value: '4200522033',
    label: 'Santa Barbara Cottage Hospital Program'
  },
  {
    value: '4200531032',
    label: 'Santa Clara Valley Medical Center Program'
  },
  {
    value: '4200721038',
    label: 'University of Colorado Program'
  },
  {
    value: '4200811040',
    label: "St Vincent's Medical Center Program"
  },
  {
    value: '4200812039',
    label: 'Bridgeport'
  },
  {
    value: '4200821043',
    label: 'Yale-New Haven'
  },
  {
    value: '4200821216',
    label: 'Norwalk Hospital Program'
  },
  {
    value: '4200821225',
    label: 'University of Connecticut Program'
  },
  {
    value: '4200822041',
    label: 'Hartford Hospital Program'
  },
  {
    value: '4200911044',
    label: 'Christiana Care Health Services Program'
  },
  {
    value: '4201011045',
    label: 'MedStar Health/Georgetown University Hospital Program'
  },
  {
    value: '4201021046',
    label: 'George Washington University Program'
  },
  {
    value: '4201021247',
    label: 'National Capital Consortium Program'
  },
  {
    value: '4201100261',
    label: 'Larkin Community Hospital Program'
  },
  {
    value: '4201100262',
    label: 'Aventura Hospital and'
  },
  {
    value: '4201112260',
    label: 'Florida Hospital Medical Center Program'
  },
  {
    value: '4201121048',
    label: 'University of Florida Program'
  },
  {
    value: '4201121049',
    label: 'Jackson Memorial Hospital/Jackson Health System Program'
  },
  {
    value: '4201121050',
    label: 'Mount Sinai Medical Center of Florida Program'
  },
  {
    value: '4201121051',
    label: 'University of South Florida Morsani Program'
  },
  {
    value: '4201121223',
    label: 'University of Florida College of Medicine Jacksonville Program'
  },
  {
    value: '4201121250',
    label: 'Mayo Clinic College of Medicine and Science (Jacksonville) Program'
  },
  {
    value: '4201212054',
    label:
      'Memorial Health- University Medical Center/Mercer { University School of Medicine (Savannah) Program'
  },
  {
    value: '4201221052',
    label: 'Emory University School of Medicine Program'
  },
  {
    value: '4201221053',
    label: 'Medical College of Georgia Program'
  },
  {
    value: '4201421933',
    label: 'Tripler Army Medical Center Program'
  },
  {
    value: '4201600679',
    label: 'Franciscan Health Olympia Fields Program'
  },
  {
    value: '4201611060',
    label: 'Rush University Medical Center Program'
  },
  {
    value: '4201611061',
    label: 'University of Chicago Program'
  },
  {
    value: '4201611064',
    label: 'Loyola University Medical Center Program'
  },
  {
    value: '4201612063',
    label: 'Presence Saint Francis Hospital Program'
  },
  {
    value: '4201621055',
    label: 'Cook County Health'
  },
  {
    value: '4201621059',
    label: 'McGaw Medical Center of Northwestern University Program'
  },
  {
    value: '4201621062',
    label: 'University of Illinois College of Medicine at Chicago Program'
  },
  {
    value: '4201621065',
    label: 'Southern Illinois University Program'
  },
  {
    value: '4201621217',
    label:
      'Advocate Health Care (Advocate Illinois Masonic Medical Center) Program'
  },
  {
    value: '4201621243',
    label: 'University of Illinois College of Medicine at Peoria Program'
  },
  {
    value: '4201721066',
    label: 'Indiana University School of Medicine Program'
  },
  {
    value: '4201821068',
    label: 'University of Iowa Hospitals and Clinics Program'
  },
  {
    value: '4201911069',
    label: 'University of Kansas School of Medicine Program'
  },
  {
    value: '4201912070',
    label: 'University of Kansas (Wichita) Program'
  },
  {
    value: '4202021071',
    label: 'University of Kentucky College of Medicine Program'
  },
  {
    value: '4202021230',
    label: 'University of Louisville School of Medicine Program'
  },
  {
    value: '4202111075',
    label: 'Louisiana State University (Shreveport) Program'
  },
  {
    value: '4202112074',
    label: 'Ochsner Clinic Foundation Program'
  },
  {
    value: '4202121235',
    label: 'Tulane University Program'
  },
  {
    value: '4202131259',
    label: 'Louisiana State University Program'
  },
  {
    value: '4202211076',
    label: 'Maine Medical Center Program'
  },
  {
    value: '4202311077',
    label: 'Johns Hopkins University Program'
  },
  {
    value: '4202311079',
    label: 'University of Maryland Program'
  },
  {
    value: '4202411087',
    label: 'Mount Auburn Hospital Program'
  },
  {
    value: '4202412088',
    label: 'UMMS-Baystate Program'
  },
  {
    value: '4202412089',
    label: 'St Vincent Hospital Program'
  },
  {
    value: '4202421080',
    label: 'Beth Israel Deaconess'
  },
  {
    value: '4202421081',
    label: 'Boston University'
  },
  {
    value: '4202421085',
    label: "Brigham and Women's Hospital/Harvard Medical School Program"
  },
  {
    value: '4202421086',
    label: 'Tufts Medical Center Program'
  },
  {
    value: '4202421090',
    label: 'University of Massachusetts Program'
  },
  {
    value: '4202422082',
    label: 'Lahey Clinic Program'
  },
  {
    value: '4202431083',
    label: 'Massachusetts General Hospital/Harvard Medical School Program'
  },
  {
    value: '4202500105',
    label: 'Beaumont Health'
  },
  {
    value: '4202500106',
    label: 'McLaren Health Care/Oakland/MSU Program'
  },
  {
    value: '4202511093',
    label: 'Henry Ford Hospital/Wayne State University Program'
  },
  {
    value: '4202512092',
    label: 'Beaumont Health'
  },
  {
    value: '4202512102',
    label: 'Beaumont Health'
  },
  {
    value: '4202521091',
    label: 'University of Michigan Health System Program'
  },
  {
    value: '4202521096',
    label: 'Detroit Medical Center/Wayne State University Program'
  },
  {
    value: '4202521099',
    label: 'Spectrum Health/Michigan State University Program'
  },
  {
    value: '4202521103',
    label: 'Ascension'
  },
  {
    value: '4202532101',
    label: 'St Joseph Mercy- Oakland Program'
  },
  {
    value: '4202621104',
    label: 'University of Minnesota Program'
  },
  {
    value: '4202621105',
    label: 'Mayo Clinic College of Medicine and Science (Rochester) Program'
  },
  {
    value: '4202711107',
    label: 'University of Mississippi Medical Center Program'
  },
  {
    value: '4202811108',
    label: 'University of Missouri-Columbia Program'
  },
  {
    value: '4202811111',
    label: 'Washington University/B-JH/SLCH Consortium Program'
  },
  {
    value: '4202821110',
    label: 'St Louis University School of Medicine Program'
  },
  {
    value: '4202821231',
    label: 'University of Missouri-Kansas City School of Medicine Program'
  },
  {
    value: '4203021112',
    label: 'University of Nebraska Medical Center College of Medicine Program'
  },
  {
    value: '4203021220',
    label: 'Creighton University'
  },
  {
    value: '4203211113',
    label: 'Mary Hitchcock Memorial Hospital/Dartmouth- Hitchcock Program'
  },
  {
    value: '4203311116',
    label: 'Atlantic Health'
  },
  {
    value: '4203321215',
    label: 'St Barnabas Medical Center Program'
  },
  {
    value: '4203321226',
    label: 'Rutgers New Jersey Medical School Program'
  },
  {
    value: '4203321228',
    label: 'Rutgers Robert Wood Johnson Medical School Program'
  },
  {
    value: '4203321244',
    label: 'Cooper Medical'
  },
  {
    value: '4203331115',
    label: 'Monmouth Medical Center Program'
  },
  {
    value: '4203331118',
    label: 'Newark Beth Israel Medical Center Program'
  },
  {
    value: '4203421120',
    label: 'University of New Mexico School of Medicine Program'
  },
  {
    value: '4203500255',
    label: 'Stony Brook Medicine/Mather Hospital Program'
  },
  {
    value: '4203500256',
    label: 'Arnot Ogden Medical'
  },
  {
    value: '4203500257',
    label: 'University at Buffalo Program'
  },
  {
    value: '4203511121',
    label: 'Albany Medical'
  },
  {
    value: '4203511123',
    label: 'Nassau University Medical Center Program'
  },
  {
    value: '4203511138',
    label: 'New York Presbyterian Hospital (Columbia Campus) Program'
  },
  {
    value: '4203511146',
    label: 'University of Rochester Program'
  },
  {
    value: '4203512145',
    label: 'Rochester General Hospital Program'
  },
  {
    value: '4203512254',
    label:
      'Zucker School of Medicine at Hofstra/Northwell at Staten Island University Hospital Program'
  },
  {
    value: '4203521126',
    label:
      'Montefiore Medical Center/Albert Einstein College of Medicine Program'
  },
  {
    value: '4203521129',
    label: 'New York Presbyterian Hospital (Cornell Campus) Program'
  },
  {
    value: '4203521135',
    label: 'Icahn School of Medicine at Mount Sinai Program'
  },
  {
    value: '4203521136',
    label: 'Westchester Medical Center Program'
  },
  {
    value: '4203521137',
    label: 'New York University School of Medicine Program'
  },
  {
    value: '4203521143',
    label: 'SUNY Health Science Center at Brooklyn Program'
  },
  {
    value: '4203521147',
    label: 'SUNY Upstate Medical University Program'
  },
  {
    value: '4203521214',
    label: 'Harlem Hospital Center Program'
  },
  {
    value: '4203521218',
    label:
      'Zucker School of Medicine at Hofstra/Northwell at Lenox Hill Hospital Program'
  },
  {
    value: '4203521221',
    label: 'Maimonides Medical Center Program'
  },
  {
    value: '4203521222',
    label: 'Stony Brook Medicine/University Hospital Program'
  },
  {
    value: '4203521224',
    label: 'Icahn School of Medicine at Mount Sinai (West) Program'
  },
  {
    value: '4203521229',
    label: 'Richmond University Medical Center Program'
  },
  {
    value: '4203521240',
    label: 'NYU Winthrop Hospital Program'
  },
  {
    value: '4203521251',
    label: 'Jacobi Medical Center/Albert Einstein College of Medicine Program'
  },
  {
    value: '4203531124',
    label: 'Zucker School of Medicine at Hofstra/Northwell'
  },
  {
    value: '4203611148',
    label: 'University of North Carolina Hospitals Program'
  },
  {
    value: '4203611150',
    label: 'Wake Forest University School of Medicine Program'
  },
  {
    value: '4203631149',
    label: 'Duke University Hospital Program'
  },
  {
    value: '4203800244',
    label: 'Kettering Health Network Program'
  },
  {
    value: '4203812154',
    label: 'Cleveland Clinic'
  },
  {
    value: '4203821152',
    label: 'University of Cincinnati Medical Center/College of Medicine Program'
  },
  {
    value: '4203821153',
    label:
      'Case Western Reserve University/University Hospitals Cleveland Medical Center Program'
  },
  {
    value: '4203821156',
    label: 'Ohio State University Hospital Program'
  },
  {
    value: '4203821157',
    label: 'University of Toledo Program'
  },
  {
    value: '4203821232',
    label: 'Aultman'
  },
  {
    value: '4203821242',
    label: 'Case Western'
  },
  {
    value: '4203900160',
    label: 'Oklahoma State University Center for Health Sciences Program'
  },
  {
    value: '4203912158',
    label: 'Integris Health Program'
  },
  {
    value: '4203921159',
    label: 'University of Oklahoma Health Sciences Center Program'
  },
  {
    value: '4204031160',
    label: 'Oregon Health & Science University Program'
  },
  {
    value: '4204111173',
    label: 'Temple University Hospital Program'
  },
  {
    value: '4204111174',
    label:
      'Sidney Kimmel Medical College at Thomas Jefferson University{ /TJUH Program'
  },
  {
    value: '4204121162',
    label: 'Main Line Health System/Bryn Mawr Hospital Program'
  },
  {
    value: '4204121163',
    label: 'Geisinger Health System Program'
  },
  {
    value: '4204121164',
    label: 'Penn State Milton S Hershey Medical Center Program'
  },
  {
    value: '4204121165',
    label: 'Albert Einstein'
  },
  {
    value: '4204121169',
    label:
      'Drexel University College of Medicine/Hahnemann University { Hospital Program'
  },
  {
    value: '4204121170',
    label: 'Mercy Catholic Medical Center Program'
  },
  {
    value: '4204121175',
    label: 'University of Pennsylvania Health System Program'
  },
  {
    value: '4204121176',
    label: 'Allegheny Health'
  },
  {
    value: '4204121177',
    label: 'UPMC Medical Education Program'
  },
  {
    value: '4204131171',
    label:
      'Pennsylvania Hospital of the University of Pennsylvania { Health System Program'
  },
  {
    value: '4204221182',
    label: 'University of Puerto Rico Program'
  },
  {
    value: '4204321183',
    label: 'Brown University'
  },
  {
    value: '4204521184',
    label: 'Medical University of South Carolina Program'
  },
  {
    value: '4204712186',
    label: 'Baptist Memorial'
  },
  {
    value: '4204721187',
    label: 'University of Tennessee/Methodist Healthcare Program'
  },
  {
    value: '4204721189',
    label: 'Vanderbilt University Medical Center Program'
  },
  {
    value: '4204721236',
    label: 'University of Tennessee Medical Center at Knoxville Program'
  },
  {
    value: '4204800935',
    label: 'Methodist Hospital (Houston) Program'
  },
  {
    value: '4204811194',
    label: 'University of Texas Medical Branch Hospitals Program'
  },
  {
    value: '4204811198',
    label:
      'Texas A&M College of Medicine-Scott and White Medical Center (Temple) Program'
  },
  {
    value: '4204812257',
    label: 'Texas Tech University Health Sciences Center-PLFSOM Program'
  },
  {
    value: '4204821192',
    label: 'University of Texas Southwestern Medical School Program'
  },
  {
    value: '4204821195',
    label: 'Baylor College of Medicine'
  },
  {
    value: '4204821196',
    label: 'University of Texas Health Science Center at Houston Program'
  },
  {
    value: '4204821197',
    label:
      'University of Texas Health Science Center San Antonio Joe { and Teresa Lozano Long School of Medicine Program'
  },
  {
    value: '4204821248',
    label: 'San Antonio Uniformed Services Health Education Consortium Program'
  },
  {
    value: '4204822190',
    label: 'Baylor University'
  },
  {
    value: '4204921199',
    label: 'University of Utah Program'
  },
  {
    value: '4205031200',
    label: 'University of Vermont Medical Center Program'
  },
  {
    value: '4205111201',
    label: 'University of Virginia Medical Center Program'
  },
  {
    value: '4205113252',
    label: 'Naval Medical Center (Portsmouth) Program'
  },
  {
    value: '4205121202',
    label: 'Eastern Virginia Medical School Program'
  },
  {
    value: '4205121203',
    label: 'Virginia Commonwealth University Health System Program'
  },
  {
    value: '4205412206',
    label: 'Virginia Mason Medical Center Program'
  },
  {
    value: '4205421205',
    label: 'University of Washington Program'
  },
  {
    value: '4205421207',
    label: 'Providence Sacred Heart Medical Center (Spokane) Program'
  },
  {
    value: '4205421239',
    label: 'Madigan Army Medical Center Program'
  },
  {
    value: '4205521208',
    label: 'West Virginia University Program'
  },
  {
    value: '4205612213',
    label: 'Aurora Health Care'
  },
  {
    value: '4205621210',
    label: 'University of Wisconsin Hospitals and Clinics Program'
  },
  {
    value: '4205621211',
    label: 'Medical College of Wisconsin Affiliated Hospitals Program'
  },
  {
    value: '4205622212',
    label:
      "Medical College of Wisconsin Affiliated Hospitals (St Joseph's) Program"
  }
];

const countries = [
  {
    label: 'Afghanistan',
    value: 'AF'
  },
  {
    label: 'Åland Islands',
    value: 'AX'
  },
  {
    label: 'Albania',
    value: 'AL'
  },
  {
    label: 'Algeria',
    value: 'DZ'
  },
  {
    label: 'American Samoa',
    value: 'AS'
  },
  {
    label: 'Andorra',
    value: 'AD'
  },
  {
    label: 'Angola',
    value: 'AO'
  },
  {
    label: 'Anguilla',
    value: 'AI'
  },
  {
    label: 'Antarctica',
    value: 'AQ'
  },
  {
    label: 'Antigua and Barbuda',
    value: 'AG'
  },
  {
    label: 'Argentina',
    value: 'AR'
  },
  {
    label: 'Armenia',
    value: 'AM'
  },
  {
    label: 'Aruba',
    value: 'AW'
  },
  {
    label: 'Australia',
    value: 'AU'
  },
  {
    label: 'Austria',
    value: 'AT'
  },
  {
    label: 'Azerbaijan',
    value: 'AZ'
  },
  {
    label: 'Bahamas',
    value: 'BS'
  },
  {
    label: 'Bahrain',
    value: 'BH'
  },
  {
    label: 'Bangladesh',
    value: 'BD'
  },
  {
    label: 'Barbados',
    value: 'BB'
  },
  {
    label: 'Belarus',
    value: 'BY'
  },
  {
    label: 'Belgium',
    value: 'BE'
  },
  {
    label: 'Belize',
    value: 'BZ'
  },
  {
    label: 'Benin',
    value: 'BJ'
  },
  {
    label: 'Bermuda',
    value: 'BM'
  },
  {
    label: 'Bhutan',
    value: 'BT'
  },
  {
    label: 'Bolivia',
    value: 'BO'
  },
  {
    label: 'Bosnia and Herzegovina',
    value: 'BA'
  },
  {
    label: 'Botswana',
    value: 'BW'
  },
  {
    label: 'Bouvet Island',
    value: 'BV'
  },
  {
    label: 'Brazil',
    value: 'BR'
  },
  {
    label: 'British Indian Ocean Territory',
    value: 'IO'
  },
  {
    label: 'Brunei Darussalam',
    value: 'BN'
  },
  {
    label: 'Bulgaria',
    value: 'BG'
  },
  {
    label: 'Burkina Faso',
    value: 'BF'
  },
  {
    label: 'Burundi',
    value: 'BI'
  },
  {
    label: 'Cambodia',
    value: 'KH'
  },
  {
    label: 'Cameroon',
    value: 'CM'
  },
  {
    label: 'Canada',
    value: 'CA'
  },
  {
    label: 'Cape Verde',
    value: 'CV'
  },
  {
    label: 'Cayman Islands',
    value: 'KY'
  },
  {
    label: 'Central African Republic',
    value: 'CF'
  },
  {
    label: 'Chad',
    value: 'TD'
  },
  {
    label: 'Chile',
    value: 'CL'
  },
  {
    label: 'China',
    value: 'CN'
  },
  {
    label: 'Christmas Island',
    value: 'CX'
  },
  {
    label: 'Cocos (Keeling) Islands',
    value: 'CC'
  },
  {
    label: 'Colombia',
    value: 'CO'
  },
  {
    label: 'Comoros',
    value: 'KM'
  },
  {
    label: 'Congo',
    value: 'CG'
  },
  {
    label: 'Congo, The Democratic Republic of the',
    value: 'CD'
  },
  {
    label: 'Cook Islands',
    value: 'CK'
  },
  {
    label: 'Costa Rica',
    value: 'CR'
  },
  {
    label: 'Cote D"Ivoire',
    value: 'CI'
  },
  {
    label: 'Croatia',
    value: 'HR'
  },
  {
    label: 'Cuba',
    value: 'CU'
  },
  {
    label: 'Cyprus',
    value: 'CY'
  },
  {
    label: 'Czech Republic',
    value: 'CZ'
  },
  {
    label: 'Denmark',
    value: 'DK'
  },
  {
    label: 'Djibouti',
    value: 'DJ'
  },
  {
    label: 'Dominica',
    value: 'DM'
  },
  {
    label: 'Dominican Republic',
    value: 'DO'
  },
  {
    label: 'Ecuador',
    value: 'EC'
  },
  {
    label: 'Egypt',
    value: 'EG'
  },
  {
    label: 'El Salvador',
    value: 'SV'
  },
  {
    label: 'Equatorial Guinea',
    value: 'GQ'
  },
  {
    label: 'Eritrea',
    value: 'ER'
  },
  {
    label: 'Estonia',
    value: 'EE'
  },
  {
    label: 'Ethiopia',
    value: 'ET'
  },
  {
    label: 'Falkland Islands (Malvinas)',
    value: 'FK'
  },
  {
    label: 'Faroe Islands',
    value: 'FO'
  },
  {
    label: 'Fiji',
    value: 'FJ'
  },
  {
    label: 'Finland',
    value: 'FI'
  },
  {
    label: 'France',
    value: 'FR'
  },
  {
    label: 'French Guiana',
    value: 'GF'
  },
  {
    label: 'French Polynesia',
    value: 'PF'
  },
  {
    label: 'French Southern Territories',
    value: 'TF'
  },
  {
    label: 'Gabon',
    value: 'GA'
  },
  {
    label: 'Gambia',
    value: 'GM'
  },
  {
    label: 'Georgia',
    value: 'GE'
  },
  {
    label: 'Germany',
    value: 'DE'
  },
  {
    label: 'Ghana',
    value: 'GH'
  },
  {
    label: 'Gibraltar',
    value: 'GI'
  },
  {
    label: 'Greece',
    value: 'GR'
  },
  {
    label: 'Greenland',
    value: 'GL'
  },
  {
    label: 'Grenada',
    value: 'GD'
  },
  {
    label: 'Guadeloupe',
    value: 'GP'
  },
  {
    label: 'Guam',
    value: 'GU'
  },
  {
    label: 'Guatemala',
    value: 'GT'
  },
  {
    label: 'Guernsey',
    value: 'GG'
  },
  {
    label: 'Guinea',
    value: 'GN'
  },
  {
    label: 'Guinea-Bissau',
    value: 'GW'
  },
  {
    label: 'Guyana',
    value: 'GY'
  },
  {
    label: 'Haiti',
    value: 'HT'
  },
  {
    label: 'Heard Island and Mcdonald Islands',
    value: 'HM'
  },
  {
    label: 'Holy See (Vatican City State)',
    value: 'VA'
  },
  {
    label: 'Honduras',
    value: 'HN'
  },
  {
    label: 'Hong Kong',
    value: 'HK'
  },
  {
    label: 'Hungary',
    value: 'HU'
  },
  {
    label: 'Iceland',
    value: 'IS'
  },
  {
    label: 'India',
    value: 'IN'
  },
  {
    label: 'Indonesia',
    value: 'ID'
  },
  {
    label: 'Iran, Islamic Republic Of',
    value: 'IR'
  },
  {
    label: 'Iraq',
    value: 'IQ'
  },
  {
    label: 'Ireland',
    value: 'IE'
  },
  {
    label: 'Isle of Man',
    value: 'IM'
  },
  {
    label: 'Israel',
    value: 'IL'
  },
  {
    label: 'Italy',
    value: 'IT'
  },
  {
    label: 'Jamaica',
    value: 'JM'
  },
  {
    label: 'Japan',
    value: 'JP'
  },
  {
    label: 'Jersey',
    value: 'JE'
  },
  {
    label: 'Jordan',
    value: 'JO'
  },
  {
    label: 'Kazakhstan',
    value: 'KZ'
  },
  {
    label: 'Kenya',
    value: 'KE'
  },
  {
    label: 'Kiribati',
    value: 'KI'
  },
  {
    label: 'Korea, Democratic People"S Republic of',
    value: 'KP'
  },
  {
    label: 'Korea, Republic of',
    value: 'KR'
  },
  {
    label: 'Kuwait',
    value: 'KW'
  },
  {
    label: 'Kyrgyzstan',
    value: 'KG'
  },
  {
    label: 'Lao People"S Democratic Republic',
    value: 'LA'
  },
  {
    label: 'Latvia',
    value: 'LV'
  },
  {
    label: 'Lebanon',
    value: 'LB'
  },
  {
    label: 'Lesotho',
    value: 'LS'
  },
  {
    label: 'Liberia',
    value: 'LR'
  },
  {
    label: 'Libyan Arab Jamahiriya',
    value: 'LY'
  },
  {
    label: 'Liechtenstein',
    value: 'LI'
  },
  {
    label: 'Lithuania',
    value: 'LT'
  },
  {
    label: 'Luxembourg',
    value: 'LU'
  },
  {
    label: 'Macao',
    value: 'MO'
  },
  {
    label: 'Macedonia, The Former Yugoslav Republic of',
    value: 'MK'
  },
  {
    label: 'Madagascar',
    value: 'MG'
  },
  {
    label: 'Malawi',
    value: 'MW'
  },
  {
    label: 'Malaysia',
    value: 'MY'
  },
  {
    label: 'Maldives',
    value: 'MV'
  },
  {
    label: 'Mali',
    value: 'ML'
  },
  {
    label: 'Malta',
    value: 'MT'
  },
  {
    label: 'Marshall Islands',
    value: 'MH'
  },
  {
    label: 'Martinique',
    value: 'MQ'
  },
  {
    label: 'Mauritania',
    value: 'MR'
  },
  {
    label: 'Mauritius',
    value: 'MU'
  },
  {
    label: 'Mayotte',
    value: 'YT'
  },
  {
    label: 'Mexico',
    value: 'MX'
  },
  {
    label: 'Micronesia, Federated States of',
    value: 'FM'
  },
  {
    label: 'Moldova, Republic of',
    value: 'MD'
  },
  {
    label: 'Monaco',
    value: 'MC'
  },
  {
    label: 'Mongolia',
    value: 'MN'
  },
  {
    label: 'Montserrat',
    value: 'MS'
  },
  {
    label: 'Morocco',
    value: 'MA'
  },
  {
    label: 'Mozambique',
    value: 'MZ'
  },
  {
    label: 'Myanmar',
    value: 'MM'
  },
  {
    label: 'Namibia',
    value: 'NA'
  },
  {
    label: 'Nauru',
    value: 'NR'
  },
  {
    label: 'Nepal',
    value: 'NP'
  },
  {
    label: 'Netherlands',
    value: 'NL'
  },
  {
    label: 'Netherlands Antilles',
    value: 'AN'
  },
  {
    label: 'New Caledonia',
    value: 'NC'
  },
  {
    label: 'New Zealand',
    value: 'NZ'
  },
  {
    label: 'Nicaragua',
    value: 'NI'
  },
  {
    label: 'Niger',
    value: 'NE'
  },
  {
    label: 'Nigeria',
    value: 'NG'
  },
  {
    label: 'Niue',
    value: 'NU'
  },
  {
    label: 'Norfolk Island',
    value: 'NF'
  },
  {
    label: 'Northern Mariana Islands',
    value: 'MP'
  },
  {
    label: 'Norway',
    value: 'NO'
  },
  {
    label: 'Oman',
    value: 'OM'
  },
  {
    label: 'Pakistan',
    value: 'PK'
  },
  {
    label: 'Palau',
    value: 'PW'
  },
  {
    label: 'Palestinian Territory, Occupied',
    value: 'PS'
  },
  {
    label: 'Panama',
    value: 'PA'
  },
  {
    label: 'Papua New Guinea',
    value: 'PG'
  },
  {
    label: 'Paraguay',
    value: 'PY'
  },
  {
    label: 'Peru',
    value: 'PE'
  },
  {
    label: 'Philippines',
    value: 'PH'
  },
  {
    label: 'Pitcairn',
    value: 'PN'
  },
  {
    label: 'Poland',
    value: 'PL'
  },
  {
    label: 'Portugal',
    value: 'PT'
  },
  {
    label: 'Puerto Rico',
    value: 'PR'
  },
  {
    label: 'Qatar',
    value: 'QA'
  },
  {
    label: 'Reunion',
    value: 'RE'
  },
  {
    label: 'Romania',
    value: 'RO'
  },
  {
    label: 'Russian Federation',
    value: 'RU'
  },
  {
    label: 'RWANDA',
    value: 'RW'
  },
  {
    label: 'Saint Helena',
    value: 'SH'
  },
  {
    label: 'Saint Kitts and Nevis',
    value: 'KN'
  },
  {
    label: 'Saint Lucia',
    value: 'LC'
  },
  {
    label: 'Saint Pierre and Miquelon',
    value: 'PM'
  },
  {
    label: 'Saint Vincent and the Grenadines',
    value: 'VC'
  },
  {
    label: 'Samoa',
    value: 'WS'
  },
  {
    label: 'San Marino',
    value: 'SM'
  },
  {
    label: 'Sao Tome and Principe',
    value: 'ST'
  },
  {
    label: 'Saudi Arabia',
    value: 'SA'
  },
  {
    label: 'Senegal',
    value: 'SN'
  },
  {
    label: 'Serbia and Montenegro',
    value: 'CS'
  },
  {
    label: 'Seychelles',
    value: 'SC'
  },
  {
    label: 'Sierra Leone',
    value: 'SL'
  },
  {
    label: 'Singapore',
    value: 'SG'
  },
  {
    label: 'Slovakia',
    value: 'SK'
  },
  {
    label: 'Slovenia',
    value: 'SI'
  },
  {
    label: 'Solomon Islands',
    value: 'SB'
  },
  {
    label: 'Somalia',
    value: 'SO'
  },
  {
    label: 'South Africa',
    value: 'ZA'
  },
  {
    label: 'South Georgia and the South Sandwich Islands',
    value: 'GS'
  },
  {
    label: 'Spain',
    value: 'ES'
  },
  {
    label: 'Sri Lanka',
    value: 'LK'
  },
  {
    label: 'Sudan',
    value: 'SD'
  },
  {
    label: 'Suriname',
    value: 'SR'
  },
  {
    label: 'Svalbard and Jan Mayen',
    value: 'SJ'
  },
  {
    label: 'Swaziland',
    value: 'SZ'
  },
  {
    label: 'Sweden',
    value: 'SE'
  },
  {
    label: 'Switzerland',
    value: 'CH'
  },
  {
    label: 'Syrian Arab Republic',
    value: 'SY'
  },
  {
    label: 'Taiwan, Province of China',
    value: 'TW'
  },
  {
    label: 'Tajikistan',
    value: 'TJ'
  },
  {
    label: 'Tanzania, United Republic of',
    value: 'TZ'
  },
  {
    label: 'Thailand',
    value: 'TH'
  },
  {
    label: 'Timor-Leste',
    value: 'TL'
  },
  {
    label: 'Togo',
    value: 'TG'
  },
  {
    label: 'Tokelau',
    value: 'TK'
  },
  {
    label: 'Tonga',
    value: 'TO'
  },
  {
    label: 'Trinidad and Tobago',
    value: 'TT'
  },
  {
    label: 'Tunisia',
    value: 'TN'
  },
  {
    label: 'Turkey',
    value: 'TR'
  },
  {
    label: 'Turkmenistan',
    value: 'TM'
  },
  {
    label: 'Turks and Caicos Islands',
    value: 'TC'
  },
  {
    label: 'Tuvalu',
    value: 'TV'
  },
  {
    label: 'Uganda',
    value: 'UG'
  },
  {
    label: 'Ukraine',
    value: 'UA'
  },
  {
    label: 'United Arab Emirates',
    value: 'AE'
  },
  {
    label: 'United Kingdom',
    value: 'GB'
  },
  {
    label: 'Uruguay',
    value: 'UY'
  },
  {
    label: 'Uzbekistan',
    value: 'UZ'
  },
  {
    label: 'Vanuatu',
    value: 'VU'
  },
  {
    label: 'Venezuela',
    value: 'VE'
  },
  {
    label: 'Viet Nam',
    value: 'VN'
  },
  {
    label: 'Virgin Islands, British',
    value: 'VG'
  },
  {
    label: 'Virgin Islands, U.S.',
    value: 'VI'
  },
  {
    label: 'Wallis and Futuna',
    value: 'WF'
  },
  {
    label: 'Western Sahara',
    value: 'EH'
  },
  {
    label: 'Yemen',
    value: 'YE'
  },
  {
    label: 'Zambia',
    value: 'ZM'
  },
  {
    label: 'Zimbabwe',
    value: 'ZW'
  }
];

const teams = notApplicable.concat(residencyPrograms).concat(countries);

const TEAM_LABELS = {};

teams.forEach(team => {
  TEAM_LABELS[team.value] = team.label;
});

export default TEAM_LABELS;
